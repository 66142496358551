import React, { useContext, useEffect } from "react"
import CartContext from "../contexts/CartContext"
import Icon from "../images/svg/checkout-success.svg"

import styles from "./checkout-success.module.scss"

import Layout from "../components/layout"

const CheckoutSuccess = () => {
  const { setCart } = useContext(CartContext)
  useEffect(() => {
    setCart([])
  }, [])
  return (
    <Layout>
      <main className={styles.container}>
        <h1 className={styles.title}>Votre commande est confirmée</h1>
        <Icon className={styles.image} />
        <p className={styles.paragraph}>
          Un email de confirmation vient de vous être envoyé
        </p>
      </main>
    </Layout>
  )
}

export default CheckoutSuccess
